<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import directionsTable from './components/directionsTable';
/**
 * Advanced table component
 */
export default {
  page: {
    title: "Directions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, directionsTable },
  data() {
    return {
      title: "Directions",
      directions: [],
      items: [
        {
          text: "Hiérarchie",
          href: "javascript:void(0)"
        },
        {
          text: "Directions",
          active: true
        }
      ],
      
      fields: [
        { key: "name", sortable: true },
        { key: "address", sortable: true },
        { key: "analytic_account", sortable: true },
      ]
    };
  },
  
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">  
            <directions-table/>
        </div>
      </div>
    </div>
  </Layout>
</template>